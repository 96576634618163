import { useEffect, useState } from "react";
import { Badge, Card, Col, DatePicker, Form, InputNumber, Radio, Row, Space, TimePicker, Typography } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment';
import FormWrapper from "../FormWrapper";
import { getClasses } from "containers/Classes/actions";
import "./RepositionModal.scss";
import { createRepositions } from "reducers/group-cancellation/actions";

const databaseDateFormat = 'DD-MM-YYYY';
const databaseDateTimeFormat = `${databaseDateFormat} HH:mm`;
const fractionDuration = 30;
function RepositionModal(props) {
  const {
    classReducer,
    reposition,
    getClasses,
    onClose,
    groupCancellationReducer,
    createRepositions,
  } = props;

  const classStartTime = reposition.scheduleTime.split(' - ')[0];
  const classEndTime = reposition.scheduleTime.split(' - ')[1];
  const classStartDateTime = moment(`${reposition.scheduleDate} ${classStartTime}`, databaseDateTimeFormat);
  const classEndDateTime = moment(`${reposition.scheduleDate} ${classEndTime}`, databaseDateTimeFormat);
  
  const replacementTypes = [
    {
      label: "Completa",
      value: "complete",
    }, {
      label: "Por fracciones",
      value: "by-parts"
    }
  ];

  const replacementPositionOptions = [
    {
      label: "Agregar al inicio",
      value: "addBefore"
    }, {
      label: "Agregar al final",
      value: "addAfter"
    }, {
      label: "Crear nueva clase",
      value: "newClass"
    }
  ];

  const [values, setValues] = useState({
    replacementType: replacementTypes[0].value,
    partsPerClass: 1,
  });

  const [form] = Form.useForm();

  const onChange = (formValue) => {
    setValues({
      ...values,
      ...formValue,
    });
  };

  const mapPayload = (formFieldsValue) => {
    let payload = {
      trackingID: reposition.trackingID,
      studentsForAgreement: [],
      type: formFieldsValue.replacementType,
      replacementDetail: [],
      partsPerClass: formFieldsValue.partsPerClass || 1,
    };
    formFieldsValue.dates.map((date, repositionFormIndex) => {
      if (date) {
        const position = formFieldsValue.newClassTime?.[repositionFormIndex] ? undefined : (formFieldsValue.replacementPosition || [])[repositionFormIndex];
        const cancelledClassDuration = moment.duration(classEndDateTime.diff(classStartDateTime)).asMinutes();
        const repositionDate = date.format(databaseDateFormat);
        const addBefore = position === 'addBefore';
        const addAfter = position === 'addAfter';
        const isCompleteReposition = formFieldsValue.replacementType === 'complete';
        const newClassTime = formFieldsValue.newClassTime?.[repositionFormIndex];
        
        // Calculate how many parts to use for this class
        const startIndex = repositionFormIndex;
        const remainingParts = reposition.availableParts - startIndex;
        const partsForThisClass = Math.min(formFieldsValue.partsPerClass || 1, remainingParts);

        let start, end;
        
        if (newClassTime) {
          // If it's a new class, use the selected time
          start = moment(`${repositionDate} ${newClassTime.format('HH:mm')}`, databaseDateTimeFormat);
          end = moment(start).add(fractionDuration * partsForThisClass, 'minutes');
        } else {
          // If it's an existing class, handle the before/after logic
          const replacementOriginalTime = formFieldsValue.replacementTime[repositionFormIndex];
          const repositionClassStartTime = replacementOriginalTime.split(' - ')[0];
          const repositionClassEndTime = replacementOriginalTime.split(' - ')[1];
          start = moment(`${repositionDate} ${repositionClassStartTime}`, databaseDateTimeFormat);
          end = moment(`${repositionDate} ${repositionClassEndTime}`, databaseDateTimeFormat);
          
          if (addBefore) {
            start.subtract(fractionDuration, 'minutes');
          }
          if (addAfter) {
            end.add(fractionDuration, 'minutes');
          }
        }
        if (isCompleteReposition) {
          start = moment(`${repositionDate} ${formFieldsValue.time.format('HH:mm')}`, databaseDateTimeFormat);
          end = moment(start).add(cancelledClassDuration, 'minutes');
        }
        
        payload.replacementDetail.push({
          ...reposition,
          studentsForAgreement: true,
          start: start.format(databaseDateTimeFormat+':ss'),
          end: end.format(databaseDateTimeFormat+':ss'),
          endDatetime: end.valueOf(),
          startDatetime: start.valueOf(),
          addBefore,
          addAfter,
          isReplacement: true,
          isCancelled: false,
          isCurrentClass: false,
          isPartOfReplacement: !isCompleteReposition,
          isNewClass: !!newClassTime,
          partsUsed: partsForThisClass,
        });
      }
      return date;
    });

    return payload;
  };
  const onFinish = () => {
    const payloadMapped = mapPayload(form.getFieldsValue());
    createRepositions(reposition.id, payloadMapped);
  };

  const getClassesOfTheDay = (date) => {
    return (classReducer.classesOrigin || []).find((day) => day.date === date)?.classes || [];
  };

  const dateRender = (current, today) => {
    const cellDate = current.format(databaseDateFormat);
    const classesOfTheDay = getClassesOfTheDay(cellDate);
    const isCancelledDate = cellDate === reposition.scheduleDate;
    const hasClasses = classesOfTheDay.length > 0;

    return (
      <div className={`ant-picker-cell-inner ${hasClasses && 'day-has-classes'} ${isCancelledDate && 'is-cancelled-date'}`}>
        {current.date()}
      </div>
    );
  };

  const panelRender = (panel) => {
    return (
      <Col>
        <Row>
          {panel}
        </Row>
        <Row>
          <Space direction="horizontal" size="small">
            <Badge color="#d9d9d9" text={'Clase cancelada'} />
            <Badge color="#809DB3" text={'Clase activa'} />
          </Space>
        </Row>
      </Col>
    );
  };

  const fetchClasses = (date) => {
    const payload = {
      startdate: date.startOf('month').format('YYYY-MM-DD'),
      enddate: date.endOf('month').format('YYYY-MM-DD'),
      clients: [],
      teachers: [],
      languages: [],
      groups: [reposition.groupId],
      levels: [],
    };
    getClasses(payload);
  }

  const handleOnDatePickerMonthChange = (date) => {
    fetchClasses(date);
  };

  useEffect(() => {
    if (groupCancellationReducer.loading.CREATE_REPOSITIONS?.state === 'success') {
      window.location.reload();
    }
  }, [groupCancellationReducer.loading.CREATE_REPOSITIONS]);
  
  useEffect(() => {
    fetchClasses(moment());
  }, []);
  
  const originalClassDate = moment(reposition.scheduleDate, databaseDateFormat).format('dddd DD [de] MMMM');

  const renderDatePicker = (index) => (
    <Form.Item
      label={`Fecha de reposición ${index + 1}`}
      name={['dates', index]}
    >
      <DatePicker 
        panelRender={panelRender} 
        popupClassName="reposition-datepicker" 
        onPanelChange={handleOnDatePickerMonthChange} 
        dateRender={dateRender} 
      />
    </Form.Item>
  );

  const renderExistingClassOptions = (classItem, index) => (
    <Card type="inner">
      <Col>
        <Row>
          <Typography.Text><span>Horario: </span> {classItem.scheduleTime}</Typography.Text>
          <Form.Item
            name={['replacementTime', index]}
            initialValue={classItem.scheduleTime}
            hidden
          >
            <input type="hidden" />
          </Form.Item>
        </Row>
        {classItem.scheduleDate === reposition.scheduleDate && (
          <Row>
            <div className='cancelled status-chip'>Clase cancelada</div>
          </Row>
        )}
        {renderReplacementOptions(index, classItem)}
      </Col>
    </Card>
  );

  const renderReplacementOptions = (index, classItem) => {
    if (values.replacementType !== 'by-parts' || classItem.scheduleDate === reposition.scheduleDate) {
      return null;
    }

    return (
      <Row>
        <Form.Item
          label={`Reposición de ${fractionDuration * (values.partsPerClass || 1)} minutos`}
          name={['replacementPosition', index]}
          rules={[{ required: true, message: 'Por favor selecciona una opción de reposición' }]}
        >
          <Radio.Group options={replacementPositionOptions} />
        </Form.Item>
        {form.getFieldValue(['replacementPosition', index]) === 'newClass' && (
          <Form.Item
            label="Horario"
            name={['newClassTime', index]}
            rules={[{ required: true, message: 'Por favor selecciona un horario para la clase' }]}
          >
            <TimePicker 
              popupClassName='group-schedule-timepicker' 
              minuteStep={15} 
              use12Hours
              format="h:mm a"
            />
          </Form.Item>
        )}
      </Row>
    );
  };

  const renderNewClassTimePicker = (index, selectedDate) => (
    <>
      <Typography className="ant-form-item-label">
        No hay clases programadas para el {selectedDate?.format('dddd DD [de] MMMM')}
      </Typography>
      <Card type="inner">
        <Form.Item
          label={`Reposición de ${fractionDuration * (values.partsPerClass || 1)} minutos`}
          name={['newClassTime', index]}
          rules={[{ required: true, message: 'Por favor selecciona un horario para la clase' }]}
        >
          <TimePicker 
            popupClassName='group-schedule-timepicker' 
            minuteStep={15} 
            use12Hours
            format="h:mm a"
          />
        </Form.Item>
      </Card>
    </>
  );

  const renderRepositionTimePicker = () => {
    const maxItems = values.replacementType === 'complete' ? 1 : Math.ceil(reposition.availableParts / (values.partsPerClass || 1));
    const selectedDates = form.getFieldValue('dates');
    const eventsOfSelectedDate = (selectedDates || []).map(date => getClassesOfTheDay(date?.format(databaseDateFormat)) || []);
    
    return Array.from({ length: maxItems }).map((_, index) => (
      <>
        {renderDatePicker(index)}
        {eventsOfSelectedDate[index]?.length > 0 && (
          <>
            <Typography className="ant-form-item-label">
              Clases programadas para el {selectedDates[index]?.format('dddd DD [de] MMMM')}:
            </Typography>
            {eventsOfSelectedDate[index].map(classItem => renderExistingClassOptions(classItem, index))}
          </>
        )}
        {selectedDates?.[index] && values.replacementType === 'by-parts' && !eventsOfSelectedDate[index]?.length && (
          renderNewClassTimePicker(index, selectedDates[index])
        )}
        {(!selectedDates?.[index] || (!eventsOfSelectedDate[index]?.length && values.replacementType !== 'by-parts')) && (
          <Form.Item
            name={['replacementTime', index]}
            initialValue={reposition.scheduleTime}
            hidden
          >
            <input type="hidden" />
          </Form.Item>
        )}
      </>
    ));
  };

  return (
    <div className="class-modal">
      <h1>¿Deseas reagendar esta clase?</h1>
      <Card title={reposition.clientName} type="inner">
        <Col>
          <Row>
            <Typography.Text><span>Grupo:</span> {reposition.classGroup}</Typography.Text>
          </Row>
          <Row>
            <Typography.Text><span>Horario anterior:</span> {originalClassDate} | {reposition.scheduleTime}</Typography.Text>
          </Row>
        </Col>
      </Card>
      <div>
        <FormWrapper
          name="class-reposition"
          form={form}
          initialValues={values}
          onValuesChange={onChange}
          onFinish={onFinish}
          loading={(classReducer.loading.GET_CLASSES?.state || groupCancellationReducer.loading.CREATE_REPOSITIONS?.state) === true}
          cancelButtonText="Regresar"
          saveButtonText="Continuar"
          onRedirectCancelButton={() => onClose()}
        >
          <div className="container-form">
            <Form.Item
              label="Tipo de reposición"
              name="replacementType"
              rules={[{ required: true }]}
            >
              <Radio.Group
                options={replacementTypes}
              />
            </Form.Item>
            {values.replacementType === 'by-parts' && (
              <>
                <Form.Item
                  label="Por agendar:"
                >
                  {reposition.availableParts} {reposition.availableParts > 1 ? 'fracciones' : 'fracción'} de {fractionDuration} minutos
                </Form.Item>
                <Form.Item
                  label="Partes por clase"
                  name="partsPerClass"
                  rules={[
                    { required: true, message: 'Por favor selecciona cuántas partes usar por clase' },
                    { type: 'number', min: 1, max: reposition.availableParts, message: `Debe ser entre 1 y ${reposition.availableParts} partes` }
                  ]}
                >
                  <InputNumber 
                    min={1} 
                    max={reposition.availableParts} 
                    placeholder="Selecciona las partes a usar"
                  />
                </Form.Item>
              </>
            )}
            {renderRepositionTimePicker()}
            {values.replacementType === 'complete' && (
              <Form.Item
                label="Horario"
                name="time"
                rules={[
                  {
                    required: true,
                    message: 'Ingresa un rango de horas válido'
                  }
                ]}
              >
                <TimePicker 
                  popupClassName='group-schedule-timepicker' 
                  minuteStep={ 15 } 
                  use12Hours
                  format="h:mm a"
                />
              </Form.Item>
            )}
          </div>
        </FormWrapper>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getClasses, createRepositions }, dispatch);
const mapStateToProps = (state) => ({
  classReducer: state.get('classReducer'),
  groupCancellationReducer: state.get('groupCancellationReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(RepositionModal);
